/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from 'react';
import {
  Link
} from "react-router-dom";
import { useDataContext } from '../context/data';

export default function Footer() {
  const { menu } = useDataContext();
  return (
    <Fragment>
      {/* {sponsors && sponsors.length > 0 && (
        <div className="sponsors sponsors--footer">
          <div className="container">
            <p>Onze sponsoren</p>
            <div className="sponsors__inner">
              {sponsors.map((sponsor) => (
                <figure key={sponsor.title} className="sponsors__item">
                  <img alt={sponsor.title} src={sponsor.logo || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNksPf9DwACYAGNJEiR2AAAAABJRU5ErkJggg=="} />
                </figure>
              ))}
            </div>
          </div>
        </div>
      )} */}

      <footer>
        <div className="container">
          <section className="footer__links">
            <div className="row">
              {menu.filter((m) => m.menu === 'footer' && m.children.length > 0).map((item) => (
                <div className="col__s--12 col__m--4">
                  <div className="col__inner">
                    <h2>{item.label}</h2>
                    {item.children && (
                      <ul>
                        {item.children.map((item) => (
                          <li key={item.label}>
                            {item.blank ? (
                              <a className={item.label} rel="noreferrer" href={item.url}>
                              {item.label}
                              </a>
                            ) : (
                              <Link className={item.label} to={`${item.url}`}>{item.label}</Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
              {menu.filter((m) => m.menu === 'footer' && m.children.length === 0).length > 0 && (
                <div className="col__s--12 col__m--4">
                  <div className="col__inner">
                    <h2>Overige links</h2>
                    <ul>
                      {menu.filter((m) => m.menu === 'footer' && m.children.length === 0).map((item) => (
                        <li key={item.label}>
                          {item.blank ? (
                            <a className={item.label} rel="noreferrer" href={item.url}>
                            {item.label}
                            </a>
                          ) : (
                            <Link className={item.label} to={`${item.url}`}>{item.label}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
               )}
            </div>
          </section>

          <section className="footer__disclaimer">
            Stichting behoud Hoogveenkerkhof Barger-Compascuum
          </section>
        </div>
      </footer>
    </Fragment>
  )
};
