import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import { DataWrapper } from './context/data';

import Header from './components/header';
import Footer from './components/footer';

import News from './pages/news';
import Home from './pages/home';
import NewsItem from './pages/newsItem';
import Page from './pages/page';
import Album from './pages/album';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <DataWrapper>
      <Router>
        <ScrollToTop />
        <Header />
        <main>
          <div className="hero" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/hero.png)`}}>
            <div>
              <p>Stichting behoud </p>
              <h1>Hoogveenkerkhof Barger-Compascuum</h1>
            </div>
          </div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/nieuws" element={<News />} />
            <Route path="/nieuws/:slug" element={<NewsItem />} />
            <Route path="/album/:slug" element={<Album />} />
            <Route path="/:slug" element={<Page />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </DataWrapper>
  );
};
