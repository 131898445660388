import React, { useEffect, useState } from 'react';
// import { request } from 'graphql-request';
import {
  useParams
} from "react-router-dom";
import Loading from '../components/loading';
import HeaderBack from '../components/headerBack';
import { request } from '../context/data';

const imagePlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAAB65masAAAAX0lEQVRYCe3SsQ3AMBDDQMcLeYyskP0nCdyyp93wOzUScPhnvd+4cfPG6N5s+Jh81FFrAj2XRsviqCmi5ag1WhZHTREtR63Rsjhqimg5ao2WxVFTRMtRa7QsjpoiWv4BPSEBHnDgQEAAAAAASUVORK5CYII=';


export default function NewsItem () {
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  let { slug } = useParams();

  useEffect(() => {
    const fetchNewsItem = async () => {
      const data = await request(
        process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql',
        `query {
          post(id: "${slug}", idType:SLUG) {
            title
            content
          }
        }`
        ,
        {}
      );
      setNewsItem(data.post || null);
      setLoading(false);
    }
    fetchNewsItem();
  }, [slug]);

  return (
    <div className="container">
      {loading && <Loading />}
      {newsItem && (
        <article className="box">
          <HeaderBack><h1>{newsItem.title}</h1></HeaderBack>
          {newsItem.featuredImage && (
            <figure className="news__image">
              <img alt={newsItem.title} src={newsItem.featuredImage?.node?.mediaItemUrl ? newsItem.featuredImage.node.mediaItemUrl : imagePlaceholder} />
            </figure>
          )}
          <div dangerouslySetInnerHTML={{__html: newsItem.content}} />
        </article>
      )}
    </div>
  )
}
