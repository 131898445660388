/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import { useDataContext } from '../context/data';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // toggle the menu
    document.getElementById("openSidebarMenu").checked = false;
  }, [pathname]);

  return null;
}

export default function Header() {
  const { menu } = useDataContext();
  return (
    <header className="header">
      <ScrollToTop />
      <div className="header__top">
        <div className="container"><p>Stichting behoud Hoogveenkerkhof Barger-Compascuum</p></div>
      </div>
      <input type="checkbox" className="menu__checkbox" id="openSidebarMenu"></input>
      <div className="header__main">
        <div className="container">
          <Link to="/" className="logo"><img src={process.env.PUBLIC_URL + "/logo.png"} /></Link>
          <label htmlFor="openSidebarMenu" className="menu">
            <span className="toggle">
              <span />
              <span />
              <span />
            </span>
          </label>
        </div>
      </div>
      <nav>
        <ul>
          {menu.filter((m) => m.menu === 'main').map((item) => (
            <li key={item.label}>
              {item.blank ? (
                <a className={item.label} rel="noreferrer" href={item.url}>
                {item.label}
                </a>
              ) : (
                <Link className={item.label} to={`${item.url}`}>{item.label}</Link>
              )}
              {item.children && (
                <ul>
                  {item.children.map((item) => (
                    <li key={item.label}>
                      {item.blank ? (
                        <a className={item.label} rel="noreferrer" href={item.url}>
                        {item.label}
                        </a>
                      ) : (
                        <Link className={item.label} to={`${item.url}`}>{item.label}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
};
