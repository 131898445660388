import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import 'unfetch/polyfill'

export const request = async (
  url,
  query,
  variables = null,
) => {
  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const result = await rawResponse.json();
  return result.data;
}

const DataContext = createContext();

export function DataWrapper({ children }) {
  const url = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql';
  const [menu, setMenu] = useState([]);

  const [news, setNews] = useState({});
  const [newsPage, setNewsPage] = useState(1);
  const [newsPageInfo, setNewsPageInfo] = useState({});
  const [newsLoading, setNewsLoading] = useState(true);
  const newsPageSize = 10;

  const [albums, setAlbums] = useState([]);

  const initialFetch = useCallback(async () => {
    const data = await request(
      url,
      `query {
        posts (first: ${newsPageSize}) {
          nodes {
            id
            title
            slug
            excerpt
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
        ${menu.length === 0 ? `
          appMenuItems(where: {parent: 0, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              title
              menuItemFields {
                blank
                menu
                url
              }
              children (where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                nodes {
                  ... on AppMenuItem {
                    id
                    title
                    menuItemFields {
                      blank
                      menu
                      url
                    }
                  }
                }
              }
            }
          }
        ` : ''}
        albums {
          nodes {
            slug
            id
            title
            excerpt
            content
            albumFields {
              photo1 { mediaItemUrl }
              photo2 { mediaItemUrl }
              photo3 { mediaItemUrl }
              photo4 { mediaItemUrl }
              photo5 { mediaItemUrl }
              photo6 { mediaItemUrl }
              photo7 { mediaItemUrl }
              photo8 { mediaItemUrl }
              photo9 { mediaItemUrl }
              photo10 { mediaItemUrl }
            }
          }
        }
      }`
    );

    setNews({ 1: data.posts?.nodes || [] });
    setNewsPageInfo({ 1: data.posts?.pageInfo });
    setNewsLoading(false);
    setNewsPage(1);

    setAlbums(data.albums?.nodes ?
      data.albums.nodes.map((album) => ({
        id: album.id,
        slug: album.slug,
        title: album.title,
        excerpt: album.excerpt,
        content: album.content,
        photos: [
          ...(album.albumFields.photo1 ? [album.albumFields.photo1.mediaItemUrl] : []),
          ...(album.albumFields.photo2 ? [album.albumFields.photo2.mediaItemUrl] : []),
          ...(album.albumFields.photo3 ? [album.albumFields.photo3.mediaItemUrl] : []),
          ...(album.albumFields.photo4 ? [album.albumFields.photo4.mediaItemUrl] : []),
          ...(album.albumFields.photo5 ? [album.albumFields.photo5.mediaItemUrl] : []),
          ...(album.albumFields.photo6 ? [album.albumFields.photo6.mediaItemUrl] : []),
          ...(album.albumFields.photo7 ? [album.albumFields.photo7.mediaItemUrl] : []),
          ...(album.albumFields.photo8 ? [album.albumFields.photo8.mediaItemUrl] : []),
          ...(album.albumFields.photo9 ? [album.albumFields.photo9.mediaItemUrl] : []),
          ...(album.albumFields.photo10 ? [album.albumFields.photo10.mediaItemUrl] : []),
        ]
      })) : []);

    if (data.appMenuItems) {
      setMenu(data.appMenuItems.nodes.map((item) => ({
        label: item.title,
        url: item.menuItemFields.url || '/',
        menu: item.menuItemFields.menu || 'main',
        blank: item.menuItemFields.blank || false,
        children: item.children?.nodes?.length > 0 ?
          item.children?.nodes?.map((subitem) => ({
            label: subitem.title,
            url: subitem.menuItemFields.url || '/',
            menu: subitem.menuItemFields.menu || 'main',
            blank: subitem.menuItemFields.blank || false,
            children: [],
          }))
        :
          []
      })));
    }
  }, [url, menu.length]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const fetchNews = async (newPage = 1, after = null, before = null) => {
    if(!news[newPage] || !newsPageInfo[newPage]) {
      setNewsLoading(true);
      let params = `first: ${newsPageSize}`;
      if (newPage > 1) {
        if (after) params = `${params}, after: "${after}"`;
        if (before) params = `${params}, before: "${before}"`;
      }

      const data = await request(
        url,
        `query {
          posts (${params}) {
            nodes {
              id
              title
              slug
              excerpt
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }`
      );

      setNews({
        ...news,
        [newPage]: data.posts?.nodes || []
      });
      setNewsPageInfo({
        ...newsPageInfo,
        [newPage]: data.posts?.pageInfo
      });
      setNewsLoading(false);
    }
    setNewsPage(newPage);
  };

  const state = {
    loading: newsLoading,
    news: {
      page: newsPage,
      items: news[newsPage] || [],
      pageInfo: newsPageInfo[newsPage] || {},
      loading: newsLoading,
      fetchNews,
    },
    menu,
    albums: {
      items: albums,
    }
  }

  return (
    <DataContext.Provider value={state}>
      {children}
    </DataContext.Provider>
  );
}

export function useDataContext() {
  return useContext(DataContext);
}
