import React from 'react';
import { useNavigate } from "react-router-dom";

const HeaderBack = ({ children }) => {
  const navigate = useNavigate();
  return <div className="back">
    <button onClick={() => navigate(-1)} className="back">Terug</button>
    {children}
  </div>;
}

export default HeaderBack;
