import React, { Fragment } from 'react';
import Loading from '../components/loading';
import {
  Link
} from "react-router-dom";

import { useDataContext } from '../context/data';

const imagePlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAAB65masAAAAX0lEQVRYCe3SsQ3AMBDDQMcLeYyskP0nCdyyp93wOzUScPhnvd+4cfPG6N5s+Jh81FFrAj2XRsviqCmi5ag1WhZHTREtR63Rsjhqimg5ao2WxVFTRMtRa7QsjpoiWv4BPSEBHnDgQEAAAAAASUVORK5CYII=';

export default function News() {
  const { news, albums } = useDataContext();

  if (!news) {
    return <Loading />
  }

  return (
    <Fragment>
      <div className="container">
        { news.items && news.items[0] && (
          <article className="col__inner box box--with-border news-item news-item--large">
              <Fragment>
                {news.items[0].featuredImage && (
                  <figure className="news__image">
                    <img alt={news.items[0].title} src={news.items[0].featuredImage?.node?.mediaItemUrl ? news.items[0].featuredImage.node.mediaItemUrl: imagePlaceholder} />
                  </figure>
                )}
                <section>
                  <div>
                    <h1>{news.items[0].title}</h1>
                    <div dangerouslySetInnerHTML={{__html: news.items[0].excerpt || 'Lees het volledige bericht voor meer informatie'}} />
                    <Link to={`/nieuws/${news.items[0].slug}`} className="btn btn--red">
                      Meer lezen
                    </Link>
                  </div>

                </section>
              </Fragment>
          </article>
        )}

        <div className="row">
          <div className="col__s--12 col__m--6">
            {news && news.items.length > 0 && (
              <div className="col__inner box box--with-border">
                <h1>Laatste nieuws</h1>
                <ul className="links">
                  {news.items.map(post => (
                    <li key={post.id}>
                      <Link to={`/nieuws/${post.slug}`}>
                        {post.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Link to="/nieuws" className="btn btn--red">
                  Meer nieuws
                </Link>
              </div>
            )}
          </div>
          <div className="col__s--12 col__m--6">
            {albums && albums.items.length > 0 && (
              <div className="col__inner box box--with-border">
                <h1>Foto's</h1>
                <ul className="albums">
                  {albums.items.map((album) => (
                    <li key={album.id}>
                      <Link to={`/album/${album.slug}`}>
                        <figure>
                          <img src={album.photos[0] || imagePlaceholder} />
                        </figure>
                        <span>{album.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

      </div>
    </Fragment>
  )
}
