import React, { useEffect, useState } from 'react';
// import { request } from 'graphql-request';
import {
  useParams
} from "react-router-dom";
import Loading from '../components/loading';
import HeaderBack from '../components/headerBack';
import { request } from '../context/data';

export default function Page () {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  let { slug } = useParams();

  useEffect(() => {
    const fetchPage = async () => {
      const data = await request(
        process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql',
        `query {
          page(id: "${slug}", idType:URI) {
            content
            title
          }
        }`
        ,
        {}
      );

      setPage(data.page ? {
        ...data.page,
        content: data.page.content.replace(/\[\/?et_pb(.*?)\]/ig, '')
      } : null);
      setLoading(false);
    }
    fetchPage();
  }, [slug]);

  return (
    <div className="container">
      {loading && <Loading />}
      {page && (
        <article className="box">
          <HeaderBack><h1>{page.title}</h1></HeaderBack>
          <div dangerouslySetInnerHTML={{__html: page.content}} />
        </article>
      )}
    </div>
  )
}
