import React, { useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import Loading from '../components/loading';
import HeaderBack from '../components/headerBack';

import { useDataContext } from '../context/data';

const imagePlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAAB65masAAAAX0lEQVRYCe3SsQ3AMBDDQMcLeYyskP0nCdyyp93wOzUScPhnvd+4cfPG6N5s+Jh81FFrAj2XRsviqCmi5ag1WhZHTREtR63Rsjhqimg5ao2WxVFTRMtRa7QsjpoiWv4BPSEBHnDgQEAAAAAASUVORK5CYII=';

export default function Album () {
  const { albums, loading  } = useDataContext();
  const [ album, setAlbum ] = useState(null);
  const [albumLoading, setAlbumLoading ] = useState(true);
  let { slug } = useParams();

  useEffect(() => {
    if (albums && albums.items.find((a) => a.slug === slug)) {
      setAlbum(albums.items.find((a) => a.slug === slug));
      setAlbumLoading(false);
    }
    if (albums && !loading && !albums.items.find((a) => a.slug === slug)) {
      setAlbumLoading(false);
    }
  }, [albums, album, setAlbum, setAlbumLoading, slug]);

  return (
    <div className="container">
      {(loading || albumLoading) && <Loading />}
      {album && (
        <article className="box box--with-background-top">
          <HeaderBack><h1>{album.title}</h1></HeaderBack>
          {albums && albums.items.length > 0 && (
            <ul className="album">
              {album.photos.map((photo) => (
                <li key={photo}>
                  <figure>
                    <img src={photo || imagePlaceholder} />
                  </figure>
                </li>
              ))}
            </ul>
          )}
        </article>
      )}
    </div>
  )
}
